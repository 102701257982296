// @vue/component
import {useImageStore} from "@/stores/WheelAnalysis/image";
import {storeToRefs} from "pinia/dist/pinia";
import {useChallengeStore} from "@/stores/WheelAnalysis/challenge";

export default {
  name: 'ImageView',

  components: {},

  mixins: [],

  props: {},

  setup() {
    const imageStore = useImageStore()
    const challengeStore = useChallengeStore()

    const { imageFile } =  storeToRefs(imageStore)
    const { previewImage } =  storeToRefs(imageStore)
    const { editMode } =  storeToRefs(challengeStore)

    return { imageStore, imageFile, previewImage, editMode }

  },

  computed: {},

  watch: {
    imageFile() {
      //console.log(newImageFile)
      //this.imageStore.imageFile = newImageFile[0]
     // this.imageStore.imageFileChanged()
    },
  },

  methods: {
    uploadImage(e){
      //console.log(e)
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
        this.previewImage = e.target.result;
        this.imageStore.previewImage = this.previewImage
        //console.log(this.previewImage);
        this.imageStore.imageFileChanged()
      };

    }
  },

  mounted () {
    this.imageStore.fetchWheelAnalysis()
  },

}
